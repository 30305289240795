import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeFinal.vue'

const router = createRouter({
  history: createWebHistory('/'),
  routes: [

    {
      path: '/',
      name: 'language',
      component: () => import('../views/SysLanguage.vue')
    },

    {
      path: '/menu',
      name: 'menu-list',
      component: () => import('../views/MenuList.vue')
    },

    {
      path: '/book',
      name: 'home',
      component: HomeView
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/AboutView.vue')
    },
    // {
    //   path: '/menu-list',
    //   name: 'menu-list',
    //   component: () => import('../views/MenuList.vue')
    // }
  ]
})

export default router
