import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/main.css'
import './assets/animation.css'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
  })

const app = createApp(App)

if (localStorage.getItem('application_language')) {
  app.config.globalProperties.$website_lang = localStorage.getItem('application_language');
} else {
  localStorage.setItem('application_language', 'en');
  app.config.globalProperties.$website_lang = 'en';
}

app.use(vuetify).use(router).mount('#app')

