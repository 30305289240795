
<script setup>
/* eslint-disable */
import { defineEmits, ref, onMounted } from 'vue';


const emit = defineEmits(['change-comp']);
const menuList = ref([])

const select = ref('')

onMounted(() => {
menuList.value = JSON.parse(localStorage.getItem('menu_data'));
});
const goToNextPage = (param,sectionId,index) => {
  select.value = index;
  emit('change-comp', { param, sectionId });
}
</script>

<template>
    <div class="side-menu">
      <div class="colored-div1 cz" @click="goToNextPage(0)">
        <!-- <i class="las la-times"></i>  -->
       <h5  style="display: flex;justify-content: center;"> <img src="../assets/image/close2.svg"></h5>
      </div>
  <div v-for="(menu,index) in menuList.menu_sections" :class="'colored-div c' + (index + 1)" @click="goToNextPage(
        index + 1 + 1,menu.section_id,index
      )">
    
    <p :class="index === select ? 'text-b' : ''" v-if="$website_lang === 'ar'" v-html="menu.title_ar"></p>
    <p :class="index === select ? 'text-b' : ''" v-if="$website_lang === 'en'" v-html="menu.title"></p>
      </div>

      
      <!-- <div class="colored-div c1" @click="goToNextPage(2)">
        <p>All day <br>breakfast</p>
      </div>
      <div class="colored-div c2" @click="goToNextPage(3)">
        <p>Savory <br>pastry</p>
      </div>
      <div class="colored-div c3" @click="goToNextPage(4)">
        <p>Toasts and sandwiches</p>
      </div>
      <div class="colored-div c4" @click="goToNextPage(5)">
        <p>Salads, <br>baked and <br>dips</p>
      </div>
      <div class="colored-div c5" @click="goToNextPage(5)">
        <p>Homemade <br>pasta</p>
      </div> -->
    </div>
</template>
<style scoped>
 .sidemenu {
  padding: 0;
}
.sidemenu div {
  /* writing-mode: vertical-rl; */
  width: 100%;
  /* text-align: center; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
}
.sidemenu div p {
  /* margin-top: -24px; */
  line-height: 20px;
}
.text-b{
    font-weight:bold ! important;
}​
.color1 {
  background: #f9d3d5;
  color: #a2514c;
}
.color1-tail {
  position: absolute;
  top: 185px;
  left: 0px;
  width: 100%;
  height: 23%;
  background-size: cover;
  background-position: center;
  /* opacity: 0.5; */
  z-index: 6666666;
  background: #f9d3d5;
  border-radius: 42px 0px 0px 0px !important;
  box-shadow: -3px -10px 21px -15px #00000040;
}
.color2 {
  background: #dfacae;
  color: #893633;
}
.color3 {
  background: #d28d8f;
  color: #893633;
}
.color4 {
  background: #b9787a;
  color: #88403f;
}
.side-menu {
  padding: 0;
  height: 100vh;
  overflow: auto;
}
.mt5 {
  margin-top: 5pc;
}
.main-area {
  padding: 1px 25px;
  height: 100vh;
  overflow: auto;
  width: 80%;
}
.main-area p {
  color: #a8665f;
  font-size: 18px;
}
.sidemenu {
  position: relative;
}
.color1::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -1;
  /* Move the pseudo-element behind the color2 div */
  background: #a8665f;
  border-radius: 42px 0px 0px 0px !important;
  box-shadow: -3px -10px 21px -15px #00000040;
}
.colored-div {
  width: 100%;
  height: 24%;
  /*    
  margin-right: 10px; */
  text-transform: uppercase;
  font-family: 'Continua';
  position: relative;
}
.colored-div1 {
  width: 100%;
  height: 5%;
  /*    
      margin-right: 10px; */
  position: relative;
}
.cz {
  background-color: #fee7e8;
}
.cz::after {
  content: "";
    position: absolute;
    top: 19px;
    width: 100%;
    height: 113%;
    background-color: #fee7e8;
    z-index: 666;
    box-shadow: 10px 9px 21px -15px #00000040;
    border-radius: 1px 0px 33px 0px !important;
}
.cz img{
  width: 25%;
    margin-top: 22px;
    position: absolute;
    z-index: 6666;
  }
.cz p {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  position: absolute;
  z-index: 6666;
  font-size: 20px;
  line-height: 24px;
  top: 100%;
  margin: 0;
  right: 44%;
  color: #a2514c;
}
.c1 {
  background-color: #ffcbce;
}
.c1::after {
  content: "";
  position: absolute;
  top: 67px;
  width: 100%;
  height: 86%;
  background-color: #ffcbce;
  z-index: 666;
  box-shadow: 10px 9px 21px -15px #00000040;
  border-radius: 1px 0px 33px 0px !important;
}
.c1 p {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  position: absolute;
  z-index: 6666;
  font-size: 13px;
  line-height: 21px;
  top: 55%;
  margin: 0;
  right: 28%;
  font-weight: 400;
  color: #9d4146;
  text-align: center;
}
.c2 {
  background-color: #e69fa4;
}
.c2::after {
  content: "";
  position: absolute;
  top: 67px;
  width: 100%;
  height: 86%;
  background-color: #e69fa4;
  z-index: 666;
  border-radius: 1px 0px 33px 0px !important;
  box-shadow: 10px 9px 21px -15px #00000040;
}
.c2 p {
writing-mode: vertical-rl;
  transform: rotate(180deg);
    position: absolute;
    z-index: 6666;
    font-size: 13px;
    line-height: 21px;
    top: 65%;
    margin: 0;
    right: 28%;
    font-weight: 400;
    color: #9d4146;
    text-align: center;
}
.c3 {
  background-color: #dd7d83;
}
.c3::after {
content: "";
position: absolute;
top: 67px;
width: 100%;
height: 86%;
background-color: #dd7d83;
z-index: 666;
border-radius: 1px 0px 33px 0px !important;
box-shadow: 10px 9px 21px -15px #00000040;
}
.c3 p {
writing-mode: vertical-rl;
transform: rotate(180deg);
position: absolute;
z-index: 6666;
font-size: 13px;
line-height: 21px;
/* top: 56%; */
top: 50%;
margin: 0;
right: 28%;
font-weight: 400;
color: #9d4146;
text-align: center;
}
.c4 {
  background-color: #c0696d;
}
.c4::after {
  content: "";
  position: absolute;
  top: 67px;
  width: 100%;
  height: 86%;
  background-color: #c0696d;
  z-index: 666;
  border-radius: 1px 0px 33px 0px !important;
  box-shadow: 10px 9px 21px -15px #00000040;
}
.c4 p {
  writing-mode: vertical-rl;
    transform: rotate(180deg);
    position: absolute;
    z-index: 6666;
    font-size: 14px;
    line-height: 21px;
    /* top: 63%; */
    top: 50%;
    margin: 0;
    right: 16%;
    font-weight: 400;
    color: #8d3d3f;
    text-align: center;
}
.c5 {
  background-color: #9c5356;
  border-radius: 1px 0px 33px 0px !important;
}
.c5 p {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  position: absolute;
  z-index: 6666;
  font-size: 14px;
  line-height: 21px;
  top: 49%;
  margin: 0;
  right: 28%;
  font-weight: 400;
  color: #753335;
  text-align: center;
}
</style>
